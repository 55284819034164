import React, { useEffect } from 'react';
import { useVideoEventHandlers } from './useVideoEventHandlers';

type VideoProps = {
    alt?: string;
    url: string;
    onLoadedMetadata?: (duration: number) => void;
    onTimeUpdate?: (currentTime: number, duration: number) => void;
    onError?: (error: MediaError) => void;
    onBuffering?: (buffered: TimeRanges) => void;
};

export const Video: React.FC<VideoProps> = React.memo(
    ({ alt, url, onLoadedMetadata, onTimeUpdate, onError, onBuffering }) => {
        const { ref: videoRef } = useVideoEventHandlers({
            onTimeUpdate,
            onLoadedMetadata,
            onError,
            onBuffering,
        });

        useEffect(() => {
            const videoElement = videoRef.current;

            const playVideo = () => {
                if (videoElement && videoElement.paused) {
                    videoElement.play().catch(() => {
                        console.error('Error automatically playing video');
                    });
                }
            };

            if (videoElement) {
                playVideo();
            }

            return () => {
                if (videoElement) {
                    videoElement.pause();
                    videoElement.currentTime = 0;
                    videoElement.load(); // this resets the video element
                }
            };
        }, [videoRef]);

        return (
            <video
                ref={videoRef}
                className="NativeVideo__video"
                title={alt || 'Native video content'}
                src={url}
                controls
                autoPlay={false}
                loop
                playsInline
                onClick={(e) => e.stopPropagation()}
                controlsList="nodownload nofullscreen"
            />
        );
    },
);
