import { useCallback, useEffect, useMemo, useRef } from 'react';

export type Handlers = {
    onBuffering?: (buffered: TimeRanges) => void;
    onError?: (error: MediaError) => void;
    onLoadedMetadata?: (duration: number) => void;
    onPlay?: () => void;
    onTimeUpdate?: (currentTime: number, duration: number) => void;
};

type EventToSubscribe = {
    event: string;
    handler: (event?: Event) => void;
};

export const useVideoEventHandlers = ({
    onError,
    onLoadedMetadata,
    onBuffering,
    onTimeUpdate,
    onPlay,
}: Handlers) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const memoizedOnLoadedMetadata = useCallback(() => {
        const videoElement = videoRef.current;
        if (videoElement && onLoadedMetadata) {
            onLoadedMetadata(videoElement.duration);
        }
    }, [onLoadedMetadata]);

    const memoizedOnTimeUpdate = useCallback(() => {
        const videoElement = videoRef.current;
        if (videoElement && onTimeUpdate) {
            onTimeUpdate(videoElement.currentTime, videoElement.duration);
        }
    }, [onTimeUpdate]);

    const memoizedOnBuffering = useCallback(
        (event) => {
            const videoElement = videoRef.current;
            if (videoElement && onBuffering) {
                onBuffering(event.target.buffered);
            }
        },
        [onBuffering],
    );

    const memoizedOnError = useCallback(
        (event) => {
            if (onError) {
                onError(event.target.error);
            }
        },
        [onError],
    );

    const memoizedOnPlay = useCallback(() => {
        if (onPlay) {
            onPlay();
        }
    }, [onPlay]);

    const eventsToSubscribe: EventToSubscribe[] = useMemo(
        () => [
            { event: 'loadedmetadata', handler: memoizedOnLoadedMetadata },
            { event: 'timeupdate', handler: memoizedOnTimeUpdate },
            { event: 'waiting', handler: memoizedOnBuffering },
            { event: 'error', handler: memoizedOnError },
            { event: 'play', handler: memoizedOnPlay },
        ],
        [
            memoizedOnLoadedMetadata,
            memoizedOnTimeUpdate,
            memoizedOnBuffering,
            memoizedOnError,
            memoizedOnPlay,
        ],
    );

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            eventsToSubscribe.forEach(
                ({ event, handler }: EventToSubscribe) => {
                    videoElement.addEventListener(event, handler);
                },
            );
        }
        return () => {
            if (videoElement) {
                eventsToSubscribe.forEach(
                    ({ event, handler }: EventToSubscribe) => {
                        videoElement.removeEventListener(event, handler);
                    },
                );
            }
        };
    }, [eventsToSubscribe]);

    return {
        ref: videoRef,
    };
};
